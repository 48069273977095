// src/components/ReadMore.js

import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../components/Core";


const HiddenText = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  margin-top: 10px;

`;

const HiddenFont = styled.p`
 font-size:10px;
 color: gold;
`;

const ReadMore = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Section py={4}>
    <Container>
      <Text>
   
      </Text>
        <Text>
        <Text>
              Dermamina is renowned for providing honest, transparent and quality services, with time and care always taken to ensure every valued patient receives a tailored treatment and result they deserve. We have true passion and expertise in the field of aesthetics and this is reflected in all our work, with a vast portfolio of amazing results all performed without surgery. 

              </Text>
              <br />
              <Text>
              At Dermamina, we take a patient centric approach, providing in depth consultation before any treatment. Our focus is to gain a full understanding of the needs and expectations of the patient in order to deliver the best possible results, and to ensure that the patient is fully informed and involved before, during and after any treatment. 

              </Text>
        </Text> 
   
    </Container>
    </Section>
  );
};

export default ReadMore;



