// Accordion.js
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const AccordionContainer = styled.div`
  border-top: 1px solid #e1e1e1;
  background-color: #ffffff;
`;

const TitleStyled = styled.div`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.5px;
  margin-bottom: 0;
  color: #1a1a1a;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`;

const UlStyled = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding-top: 0px;
`;

const AccordionItemContainer = styled.div`
  border-top: 1px solid #e1e1e1;
  padding: 10px 0;
  ${({ isLast }) =>
    isLast &&
    `
    border-bottom: 1px solid #e1e1e1;
  `}
`;

const Icon = styled.span`
  font-size: 24px;
  color: #00aec7;
  transition: transform 0.3s ease;
  ${({ isOpen }) => (isOpen ? `content: '-';` : `content: '+';`)}
`;

const AccordionItem = ({ title, children, isOpen, onClick, isLast }) => {
  const ref = useRef(null);

  return (
    <AccordionItemContainer ref={ref} isLast={isLast}>
      <TitleStyled onClick={onClick} className="accordion-title">
        {title}
        <Icon isOpen={isOpen}>{isOpen ? "-" : "+"}</Icon>
      </TitleStyled>
      <UlStyled isOpen={isOpen} className="accordion-content">
        {children}
      </UlStyled>
    </AccordionItemContainer>
  );
};

const Accordion = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <AccordionContainer>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          isOpen={openIndex === index}
          onClick={() => handleAccordionClick(index)}
          isLast={index === items.length - 1}
        >
          {item.content}
        </AccordionItem>
      ))}
    </AccordionContainer>
  );
};

export default Accordion;
