import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import HeroComponent from "../components/HeroComponent";
import LipsGlance from "../components/glance.js";
import Why from "../components/why.js";
import Accordion from "../components/Accordion.js";
import imgVideo from "../assets/image/jpeg/lip-filler-doctor.png";
import Testimonial from "../components/mainreviews.js";
import Saveface from '../components/save.js';
import Trend from '../components/trends.js';
import Content from "../sections/exo/exocontent.js";
import Content2 from "../sections/exo/exocontent2.js";
import LipsFaq from "../sections/exo/exofaq.js";
import LipsSlider from "../sections/lips/lipsbefore.js";
import Head from "../components/head";
import Pro from '../sections/lips/lipsteps.js';
import Clinic from '../components/NewFind.js';  
import Award from "../components/NewToxane.js";
import { JsonLd } from '../components/JsonLd';
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import PopupForm from "../components/PopupForm/PopupForm"

const ExoPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const accordionItems = [
    { title: "What are Exosomes For Hair Loss?", content: <Content setIsModalOpen={setIsModalOpen} /> },
    { title: "Impact on hair restoration?", content: <Content2 />  },
    { title: "Why choose Dermamina", content:<Why />, isLast: true},
  ];
  return (
    <>
    <Head title="Exosomes Hair Treatment London" image="/images/dermamina-home.jpg"  description="Regrow and strengthen hair with Exosomes hair treatment. Harness your body's natural healing for fuller, healthier hair. Discover the benefits now!" keywords="Exosomes hair treatment, Exosomes Hair loss therapy, Esosomes for hair, Hair regrowth treatment, Exosomes scalp treatment, Non-surgical hair restoration, Thinning hair solution, Exosomes injections for hair, Hair rejuvenation, Natural hair growth"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
'@type': 'ContactPoint',
telephone: '020 7099 6650',
contactType: 'Customer service',
},
address:{
'@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
{'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'PRP Hair Loss',
item:'https://www.dermamina.com/prp-for-hair-loss',
},

]
}}
</JsonLd>



<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Lip Filler London',
item:'https://www.dermamina.com/lip-filler',
},

{'@type': 'ListItem', 
position:3,
name:'Dermal Fillers London',
item:'https://www.dermamina.com/dermal-fillers',
},

]
}}
</JsonLd>


<PageWrapper headerDark>
  <PopupForm
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  />
<HeroComponent
    itext='<span style="color: #1a1a1a;">Exosomes</span> <span style="color: #00aec7;">Hair Loss</span>'
    secondText="Unlock the Secrets to Fuller, Healthier Hair with Exosome"
    videoUrl="https://www.youtube.com/watch?v=pbWMgcunKe0" // Specify your video URL here
    videoAlt="Exosomes Therapy Video"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

<Section py={0} className="pt-lg-5 pb-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
<LipsGlance
   best="5 Sessions"
  bestalt="best exosomes therapy"
  resultstext="Long Lasting"
  resultsalt="exosomes therapy results"
  costdata="£450"
  costalt="exosomes therapy Cost"
  timedata="40 Minutes"
  timealt="Exosomes Hair Loss Duration Time"
  workdata="Immediately"
  workalt="Exosomes Hair Loss downtime work"
  paindata="None / Minimal"
  painalt="Exosomes Hair Loss Pain"
  />
 </Col>
 <Col className="d-lg-none d-xl-none" lg="6" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Accordion items={accordionItems} /></Col>
  </Row>
  </Container>
  </Section>
<Testimonial
  setIsModalOpen={setIsModalOpen}
/>
<Award />
<LipsFaq setIsModalOpen={setIsModalOpen}/>
<Clinic />   
<Saveface />
<Trend />
</PageWrapper>

    </>
  );
};
export default ExoPage;
