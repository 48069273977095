import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import Link from 'gatsby-link'
import { Title, Section, Box, Span, Text } from "../components/Core";
import trust from "../assets/image/png/trust.svg";
import google from "../assets/image/jpeg/google-reviews.svg";
import Himage from '../assets/image/jpeg/micro.jpg';
import { device, breakpoints } from "../utils";
import styled from 'styled-components';
import Perform from "./numbers";


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;


const Texttitle = styled.p`

font-size:14px;
text-transform:uppercase;
color:rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  font-size:12px;
 
}

`;

const Textdesc = styled.p`

font-size:16px;
color: #1a1a1a;

@media screen and (max-width: 991px)
 {
    font-size:12px;
}

`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.8em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const Imagi = styled.div`

position: absolute;
left: 0%;
top: 0%;
right: 0%;
bottom: 0%;
width: 100%;
height: 100%;
background: url(${Himage}) no-repeat;
background-size: cover;
background-position: center center;
background-position: 0px 0px;

opacity:0.1;


`;

const Secti = styled.section`


position: relative;

background-color: #ffffff;


@media screen and (min-width: 767px)
{
    display: none;
}

`;

const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;



const ContentCard = ({
  color = "primary",
  className,
  iconName,
  title,
  children,
  ...rest
}) => (
  <Box
    bg="#f7f7fb"
    border="1px solid"
    borderColor="border"
    p="20px"
    borderRadius={10}
    className={`d-flex align-items-center ${className}`}
    {...rest}
    css={`
      transition: all 0.3s ease-out;
      &:hover {
        box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow};`};
      }
    `}
  >
    <Box
      size={55}
      minWidth={55}
      borderRadius="50%"
      color={color}
    
      fontSize="28px"
      className="d-flex justify-content-center align-items-center"
      css={`
        background: url(${iconName})  ;
        background-size:cover;
      `}
      mr={3}
    >
      
    </Box>

    <Title variant="card" mb={0}>
      {title}
    </Title>
  </Box>
);

const IndexReviewsPage = ({setIsModalOpen}) => (
  <>
  <Secti >
 
    <Section py={4}  className="position-relative"
     >
         <Container className="">
          <Row className="align-items-center">
            <Col md="9" lg="12" className="" data-aos="fade-up"
              data-aos-duration="400"
              data-aos-once="false"
              data-aos-delay="100">
      
                   <Iwrap>


<Itext as="h2">Non Surgical <span>Treatment Reviews</span></Itext>
</Iwrap>
      <SecondText>
    What our customers think
      </SecondText>


            
     
            </Col>
            </ Row>
            </Container> <br />
            <Perform />
      <Container  className="pt-3 pb-3">
     
        <Row className="justify-content-center">
          <Col
            lg="12"
            md="6"
            className="pr-0 mr-0"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="150"
          >
           

 
           <div className="sliderrecnose">
  


  <div className="slidesrecnose">


  <div className="insideslidesrecnose" id="slide-1">
      
      <Texttitle color="black">Diana</Texttitle> 
      <Textdesc color="black"> I came in for a non-surgical Rhinoplasty and really was satisfied with the service I received! Mina did very well at thoroughly explaining everything to me during the consultation. She assessed my nose and gave me a realistic idea of what could be done to achieve my desired results. The facility was extremely clean and all the staff were extremely friendly. </Textdesc><br />
      <img src={trust} width="50%" className="sliderrecimage2"/> 
    </div>

    <div className="insideslidesrecnose" id="slide-2">
      
      <Texttitle color="black">Rachel</Texttitle> 
      <Textdesc color="black"> Went to see Mina for a non-surgical nose job, having never had any kind of filler treatment before so was understandably a little nervous. However Mina was very professional and friendly, explaining the procedure clearly and checking if I had any questions or concerns. I felt completely at ease and my results looks great - would definitely recommend and return in future!</Textdesc><br />
      <img src={trust} width="50%" className="sliderrecimage2"/> 
    </div>

    <div className="insideslidesrecnose" id="slide-3">
      
      <Texttitle color="black">Amir ahmed</Texttitle> 
      <Textdesc color="black"> Before attending Dermamina I knew that the service would be absolutely amazing, I knew I was taking the right decision by choosing Dermamina to achieve the desired look I was going for. My nose looks amazing all thanks to Mina, she is one of the most amazing and caring person I’ve met. The team there were amazing and such nice people they made me feel welcome and well cared for. </Textdesc><br />
      <img src={google} width="100%" className="sliderrecimage2"/> 
    </div>

  <div className="insideslidesrecnose" id="slide-2">
      
      <Texttitle color="black">Tahilya</Texttitle> 
      <Textdesc color="black"> I started my laser treatment with Sam and she is best therapist you can ask for! She makes you feel so comfortable, and is friendly, patient and explains everything in detail! I’m already seeing huge results after two sessions and can’t wait to continue! Thank you Dermamina and Sam for your services!</Textdesc><br />
      <img src={trust} width="50%" className="sliderrecimage2"/> 
    </div>
    
  <div className="insideslidesrecnose" id="slide-1">
      
      <Texttitle color="black">Miss Harris</Texttitle> 
      <Textdesc color="black"> I had my first laser session with Dr Sam and from the moment I met her she was a dream, I'm even looking forward to my next session! Everything is so clean and professional, highly recommend! </Textdesc><br />
      <img src={trust} width="50%" className="sliderrecimage2"/> 
    </div>



    <div className="insideslidesrecnose" id="slide-3">
      
      <Texttitle color="black">Mark B</Texttitle> 
      <Textdesc color="black"> This was my first visit to have a patch test for laser hair removal. I had not been to such a place before. Very pleasant staff, good location, on time, and a helpful conversation. I will be returning for the full treatment. </Textdesc><br />
      <img src={trust} width="100%" className="sliderrecimage2"/> 
    </div>


    <div className="insideslidesrecnose" id="slide-4">
      
      <Texttitle color="black">Elsa Trojaola</Texttitle> 
      <Textdesc color="black"> Absolutely amazing, I highly recommend to everyone! Always Great Results, Dr. Mina is exceptional in what she does and always takes great care of her patients. The whole staff is excellent, friendly and welcoming always. Thank you so much! </Textdesc><br />
      <img src={google} width="100%" className="sliderrecimage2"/> 
    </div>


    <div className="insideslidesrecnose" id="slide-5">
      
      <Texttitle color="black">Laura Lauren</Texttitle> 
      <Textdesc color="black"> Mina is an amazing person, she is so patient and always go over and beyond for her clients. This was my second visit and I am very happy with my results and the excellent customer service Dermamina gives </Textdesc><br />
      <img src={google} width="100%" className="sliderrecimage2"/> 
    </div>

    <div id="slide-6">
    <Texttitle color="black">Ashana</Texttitle> 
      <Textdesc color="black">Very informed, very professional and a very comfortable experience.
All staff were welcoming and the procedure itself was successful in my opinion.  With all things time will tell if 100% successful but I’m very happy with the initial results. Fabulous clinic and I highly recommend.</Textdesc><br />
      <img src={google} className="sliderrecimage2"/> 
    </div>


    <div className="insideslidesrecnose" id="slide-7">
      
      <Texttitle color="black">Andrea Pomphrey</Texttitle> 
      <Textdesc color="black"> Professional and friendly staff and a welcoming clinician who gives clear and expert advice . Very happy with outcomes and will be returning . Great location too.  </Textdesc><br />
      <img src={trust} width="100%" className="sliderrecimage2"/> 
    </div>

    <div id="slide-8">
    <Texttitle color="black">Ria Soni</Texttitle> 
      <Textdesc color="black">I’d found this place on Instagram and i’m so glad I decided to go ahead with them!</Textdesc><br />
      <img src={trust} className="sliderrecimage2"/> 
    </div>



    
  </div>
</div>


          </Col> 
<Col className="pt-2">
<div onClick={() => setIsModalOpen(true)} data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="250" className="d-flex align-items-center justify-content-center d-block d-sm-none pt-0"
              activeClassName="active">
  <Bookbutton>Book appointment</Bookbutton>
</div>
</Col>
      
        </Row>
      </Container>
    </Section>
    </Secti>
  </>
);

export default IndexReviewsPage;
